<template>
<div>
    <Home />
</div>
</template>

<script>
import Home from '../components/Home'
export default {
    components: {
        Home
    }
}
</script>

<style>
